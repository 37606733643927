import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';

import {
  getCalendarAvailability,
  getMonthsToFetch,
  updateCalendarAvailability,
} from './helpers';

const initialState = {
  calendar: {
    lastFetched: null,
    lastFetchedParams: null,
    list: [],
    fetchedMonths: [],
    isLoading: false,
    currentMonth: null,
    isOpen: false,
  },
};

// we will just do calendar for now
// then we can add things like room availability
// or even have this as a general mechanism form request caching

const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    setCalendarAvailability: (state, action) => {
      state.calendar.list = action.payload;
      state.calendar.lastFetched = new Date();
    },
    setLastCalendarFetchedParams: (state, action) => {
      state.calendar.lastFetchedParams = action.payload;
    },
    addFetchedMonths: (state, action) => {
      state.calendar.fetchedMonths = [
        ...new Set([...state.calendar.fetchedMonths, ...action.payload]),
      ];
    },
    resetFetchedMonths: (state) => {
      state.calendar.fetchedMonths = [];
    },
    setIsLoading: (state, action) => {
      state.calendar.isLoading = action.payload;
    },
    setCurrentCalendarMonth: (state, action) => {
      state.calendar.currentMonth = action.payload;
    },
    setIsCalendarOpen: (state, action) => {
      state.calendar.isOpen = action.payload;
    },
  },
});

export const fetchCalendarAvailability =
  (productCode, currentMonth, params, moment, axios, countryCode) =>
  async (dispatch, getState) => {
    const {
      bookings: { agentProfileRatePlanAssociated },
      availability: {
        calendar: {
          fetchedMonths: fm,
          list: l,
          lastFetched,
          lastFetchedParams,
          isLoading,
        },
      },
    } = getState();

    // assign current state to writeable variables
    let fetchedMonths = fm;
    let list = l;

    // ensure that we fetch again for a different product code
    const paramsChanged = !isEqual(
      { productCode, ...params },
      { productCode, ...lastFetchedParams }
    );

    if (isLoading && paramsChanged) {
      return;
    }

    if (paramsChanged) {
      fetchedMonths = [];
    }
    const isPrevMonth =
      !!fetchedMonths.length &&
      fetchedMonths.every((month) => {
        return moment(currentMonth).isBefore(month, 'month');
      });

    let monthsToFetch = getMonthsToFetch(
      currentMonth,
      fetchedMonths,
      isPrevMonth,
      moment
    );

    const monthAlreadyFetched = fetchedMonths.includes(currentMonth);
    const expired = moment().diff(moment(lastFetched), 'minutes') > 15;
    const fetchNewBatch = monthsToFetch.length > 3;

    if (!isPrevMonth && monthAlreadyFetched && !fetchNewBatch && !expired) {
      return;
    }

    // hard refetch conditions
    if (!lastFetchedParams || paramsChanged || expired) {
      dispatch(setLastCalendarFetchedParams(params));
      dispatch(setCalendarAvailability([]));
      dispatch(resetFetchedMonths());

      list = [];
      fetchedMonths = [];

      // get the months to fetch again
      monthsToFetch = getMonthsToFetch(
        currentMonth,
        fetchedMonths,
        isPrevMonth,
        moment
      );
    }

    dispatch(setIsLoading(true));
    dispatch(addFetchedMonths(monthsToFetch));

    const results = await Promise.all(
      monthsToFetch.map((month) =>
        getCalendarAvailability(
          productCode,
          month,
          params,
          agentProfileRatePlanAssociated,
          moment,
          axios,
          countryCode
        )
      )
    );

    if (results.length > 0) {
      const updatedCalendarAvailability = updateCalendarAvailability(
        results,
        list,
        moment
      );

      dispatch(setCalendarAvailability(updatedCalendarAvailability));
    }
    dispatch(setIsLoading(false));
  };

export const {
  setCalendarAvailability,
  addFetchedMonths,
  setIsLoading,
  setLastCalendarFetchedParams,
  setCurrentCalendarMonth,
  resetFetchedMonths,
  setIsCalendarOpen,
} = availabilitySlice.actions;

export default availabilitySlice.reducer;
