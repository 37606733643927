const isDateAvailable = (day, daysAvailability, moment) => {
  const date = daysAvailability.find(
    ({ date }) => date === moment(day).format('YYYY-MM-DD')
  );
  return date?.isCheckIn || false;
};

const isDateInRange = (day, availableSelectionRange, moment) => {
  return availableSelectionRange.includes(moment(day).format('YYYY-MM-DD'));
};

const hasEnoughConsecutiveDays = (day, daysAvailability, moment) => {
  const currentDate = moment(day).format('YYYY-MM-DD');
  const currentDateIndex = daysAvailability.findIndex(
    ({ date }) => date === currentDate
  );

  if (currentDateIndex === -1) return false;

  const currentDayData = daysAvailability[currentDateIndex];
  const minStayValue = Math.max(
    currentDayData.metadata.MinStayArriveValue || 1,
    currentDayData.metadata.MinStayThroughValue || 1
  );

  let consecutiveAvailableDays = 0;
  for (let i = currentDateIndex; i < daysAvailability.length; i++) {
    if (daysAvailability[i].isCheckIn) {
      consecutiveAvailableDays++;
      if (consecutiveAvailableDays >= minStayValue) return true;
    } else {
      break;
    }
  }

  return false;
};

const getIsDayBlocked = (
  day,
  isLoading,
  daysAvailability,
  focus,
  availableSelectionRange,
  moment
) => {
  if (isLoading) return false;
  if (!daysAvailability || !availableSelectionRange) return true;

  const isAvailable = isDateAvailable(day, daysAvailability, moment);

  if (focus === 'startDate') {
    if (!isAvailable) return true;
    return !hasEnoughConsecutiveDays(day, daysAvailability, moment);
  }

  return availableSelectionRange.length > 0
    ? !isDateInRange(day, availableSelectionRange, moment)
    : !isAvailable;
};

export default getIsDayBlocked;
