import checkDateInAllowedRange from './check-date-in-allowed-range';
import mergeAvailabilityMetadata from './merge-availability-metadata';

const determineAvailabilityMetadata = (month, monthData, numRooms, moment) => {
  const daysInMonth = moment(month).daysInMonth();
  const availableDates = monthData.map(({ date }) => date);

  const monthDayAvailability = Array.from({ length: daysInMonth }).map(
    (_, d) => {
      d = d + 1;
      const dayString = d.toString().padStart(2, '0');
      const previousDayString = (d - 1).toString().padStart(2, '0');

      const { minimumPrice, availabilityForRooms = [] } =
        monthData.find(({ date }) => date === dayString) || {};

      const { availabilityForRooms: availabilityForRoomsPreviousDay = [] } =
        monthData.find(({ date }) => date === previousDayString) || {};

      const availableForAllRooms = numRooms === availabilityForRooms.length;
      const availableForAllRoomsPreviousDay =
        numRooms === availabilityForRoomsPreviousDay.length;

      const metadata = mergeAvailabilityMetadata(availabilityForRooms);

      const isCheckIn =
        !!availableDates?.includes(dayString) &&
        availableForAllRooms &&
        !metadata.NoArrive;

      const isCheckOut =
        !!availableDates?.includes(previousDayString) &&
        availableForAllRoomsPreviousDay;

      const dayData = {
        date: `${month}-${dayString}`,
        isCheckIn,
        isCheckOut,
        metadata,
      };

      if (minimumPrice && availableForAllRooms) {
        dayData.minimumPrice = minimumPrice;
      }

      return dayData;
    }
  );

  // pass over the availability again
  // and check if the check-in dates are valid based on the minimum stay requirements
  // and update the check-out dates accordingly

  monthDayAvailability.forEach((dayData, index) => {
    if (dayData.isCheckIn) {
      const minStayValue = Math.max(
        dayData.metadata.MinStayArriveValue || 1,
        dayData.metadata.MinStayThroughValue || 1
      );

      const isDateInAllowedRange = checkDateInAllowedRange(
        dayData.date,
        monthDayAvailability,
        minStayValue
      );

      if (dayData.isCheckIn) {
        const wasCheckInBefore = dayData.isCheckIn;
        dayData.isCheckIn = isDateInAllowedRange;

        // If the check-in is not valid, we need to invalidate the check-out for the next day
        if (
          wasCheckInBefore &&
          !dayData.isCheckIn &&
          index < monthDayAvailability.length - 1
        ) {
          monthDayAvailability[index + 1].isCheckOut = false;
        }
      }
    }
  });

  return monthDayAvailability;
};

export default determineAvailabilityMetadata;
